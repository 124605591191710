import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  apiDomain = `${environment.domain}`;
    getSeoApi = 'get-seo-details/'
  
  activelanguage = localStorage.getItem('browserLanguage');
  constructor(private http: HttpClient) { }

  getSeo(slug) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'), 
        'Content-Type'    : 'application/json',
				'No-Auth':'True'     
      })
    };
    return this.http.get<any>(`${this.apiDomain}${this.getSeoApi}${slug}`,httpOptions).pipe(map(res => {
      return res;
    }),
		catchError(err => {
		  if(err.status == 401)
		  {
			localStorage.removeItem('currentSubSationalUser')
			window.location.href = '/login' 
		  }
		  return err.error;
		}));
  }

}
