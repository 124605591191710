import { DOCUMENT } from "@angular/common";
import { Component, AfterContentInit, OnInit, Inject } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SeoService } from "./services/seo.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterContentInit {
  title = "Subsational";
  seosData: any;
  newUrl: any; 
  constructor(
    private router: Router,
    private translate: TranslateService,
    @Inject(DOCUMENT) private doc,
    private seoService: SeoService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.translate.addLangs(["ar", "es", "he", "ru", "eng"]);

    if (localStorage.getItem("browserLanguage")) {
      const browserLang = localStorage.getItem("browserLanguage");
      this.translate.use(browserLang.match(/ar|es|he|ru|eng/) ? browserLang : "eng");
    } else {
      localStorage.setItem("browserLanguage", "eng");
      this.translate.setDefaultLang("eng");
    }

    if(!this.translate.currentLang)
    {
      localStorage.setItem("browserLanguage", "eng");
      this.translate.setDefaultLang("eng");
    }

    this.translate.getTranslation(this.translate.currentLang ? this.translate.currentLang :'eng').subscribe();

  }

  ngOnInit() {

    //setting default language
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentRequest = this.router.url.replace("?", "/");
        this.newUrl = currentRequest.split("/");

        // if (this.newUrl.includes("signup")) {
        //   this.getSeo("signup-page");
        // } else if (this.newUrl.includes("login")) {
        //   this.getSeo("login");
        // } else if (this.newUrl.includes("about")) {
        //   this.getSeo("about-us");
        // } else if (this.newUrl.includes("instructors")) {
        //   this.getSeo("instructor");
        // } else if (this.newUrl.includes("live")) {
        //   this.getSeo("live");
        // } else if (this.newUrl.includes("video")) {
        //   this.getSeo("video");
        // } else if (this.newUrl.includes("personal")) {
        //   this.getSeo("personal");
        // } else if (this.newUrl.includes("yukaballet")) {
        //   this.getSeo("licence-yukaballet");
        // } else if (this.newUrl.includes("pure-power")) {
        //   this.getSeo("licence-purepower");
        // } else if (this.newUrl.includes("terms-conditions")) {
        //   this.getSeo("terms-conditions");
        // } else if (this.newUrl.includes("privacy-policy")) {
        //   this.getSeo("privacy-policy");
        // } else {
        //   this.getSeo("home-page");
        // }
      }
    });
  }

  ngAfterContentInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }, 1500);
      }
    });

  }

  //get seo data
  getSeo(slug) {
    this.seoService.getSeo(slug).subscribe((res) => {
      if (res.status == "success") {
        if (res.seos) {
          this.seosData = res.seos;
          // this.sendDescription(this.seosData.meta_description);
          this.titleService.setTitle(this.seosData.title);
          this.metaTagService.addTags([
            { name: "description", content: this.seosData.meta_description },
            { name: "keywords", content: this.seosData.meta_keywords },
            {
              property: "og:description",
              content: this.seosData.og_description,
            },
            { property: "og:title", content: this.seosData.og_title },
            { property: "og:type", content: this.seosData.og_type },
            { property: "og:url", content: this.seosData.og_url },
            { name: "twitter:card", content: this.seosData.twitter_card },
            { name: "twitter:url", content: this.seosData.twitter_site },
            { name: "twitter:title", content: this.seosData.og_title },
            {
              name: "twitter:description",
              content: this.seosData.og_description,
            },
            { name: "robots", content: "Follow, Index" },
            { name: "author", content: "subsational" },
            { "http-equiv": "Expires", content: "Never" },
            {
              name: "copyright",
              content: "© 2020 SubSational . ALL RIGHTS RESERVED.",
            },
            { name: "city", content: "" },
            { name: "country", content: "" },
            { name: "robots_search", content: "" },
            { name: "geo_position", content: "" },
            { name: "geo_region", content: "" },
            { name: "distribution", content: "" },
            { property: "og:site_name", content: "SubSational" },
            {
              property: "og:image:alt",
              content: "SubSational",
            },
            {
              property: "og:image",
              content: "https://subsationallive.stage04.obdemo.com/favicon.ico",
            },
          ]);

          let ogurl = this.seosData.og_url ? this.seosData.og_url : "false";
          this.updateConicalUrl(ogurl);
        }
      }
    });
  }

  // Update Conical url in index file
  updateConicalUrl(url) {
    if (url == "false") {
      if (this.doc.getElementsByTagName("link")) {
        let links = this.doc.getElementsByTagName("link");
        links[2].setAttribute("href", this.doc.URL);
      }
    } else {
      if (this.doc.getElementsByTagName("link")) {
        let links = this.doc.getElementsByTagName("link");
        links[2].setAttribute("href", url);
      }
    }
  }
}
