import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  currentUserStatus: boolean = false;
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("currentSubSationalUser")) {
        // logged in so return true
        return true;
      } else {
        // not logged in so redirect to login page with the return url
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
    }

    return true; // >>>>>>>>>>>>>>>>>>>>>> needed to add this!

    // if (localStorage.getItem("currentSubSationalUser")) {
    //   // logged in so return true
    //   //this.router.navigate(['']);
    //   return true;
    // }
    // // not logged in so redirect to login page with the return url
    // this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    // return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem("currentSubSationalUser")) {
          // logged in so return true
          return true;
        } else {
          // not logged in so redirect to login page with the return url
          this.router.navigate(["/login"], {
            queryParams: { returnUrl: state.url },
          });
          return false;
        }
      }
  
      return true; // >>>>>>>>>>>>>>>>>>>>>> needed to add this!
    // if (localStorage.getItem("currentSubSationalUser")) {
    //   // logged in so return true
    //   return true;
    // }
    // // not logged in so redirect to login page with the return url
    // this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    // return false;
  }
}
