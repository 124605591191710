import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  loading = new Subject<boolean>();
  clearnotificationscount = new Subject<boolean>();
  updateNotificationList = new Subject<boolean>();

  constructor(public toastr: ToastrManager) { }

  alertTost(res) {

    if (res && res.status == 'success') {
      this.toastr.successToastr(res.msg, 'Success!', {position: 'bottom-right',showCloseButton:true,animate:'slideFromTop'});
    } else if (res.status == 'error') {
      this.toastr.errorToastr(res.msg, 'Failed!' ,{position: 'bottom-right',showCloseButton:true,animate:'slideFromTop'});
    } else {
      this.toastr.errorToastr('Request Failed', 'Error!',{position: 'bottom-right',showCloseButton:true,animate:'slideFromTop'});
    }
  }



}
