import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Observable } from "rxjs";

@Injectable()
export class WebChatService {
  constructor(
    private socket: Socket,
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  // for restaurant chat
  public sendMessage(message) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("loginChatRoom", message);
    }
  }

  // for driver chat
  public sendDriverMessage(message) {
 
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("loginChatRoomDriver", message);
    }
  } 

  // for notification
  public sendNotificationMessage(message) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("loginChatRoomOnWebsite", message);
    }
  }

  // no use
  public sendReadMessage(message) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("readmsg", message);
    }
  }


  // get user online socket on
  public getUserOnline = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("is_user_online", (message) => {
          observer.next(message);
        });
      });
    }
  };

  // get resturant chat socket on
  public getMessages = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("chatRoom", (message) => {
          observer.next(message);
        });
      });
    }
  };

  // get notification counter socket on
  public getNotifications = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("unreadNotificationOnWebsite", (message) => {
          observer.next(message);
          //this.messageService.sendNotificationCountMessage(message)
        });
      });
    }
  };

  // get driver message chat socket on
  public getDriverMessages = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("receive_message", (message) => {
          observer.next(message);
        });
      });
    }
  };
  

  // send emit to driver
  public sendMessageToDriver(message) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("saveChatHistory", message);
    }
  }


  // for restaurant chat
  initSocket(__bool, message) {
    if (isPlatformBrowser(this.platformId)) {
      console.log('init_socket_message', message);
      const $this = this;
      if (__bool == true) {
        this.socket.connect();

        this.socket.on("connect", function () {
          // console.log('connected')
          $this.sendMessage(message);
        });
        this.socket.on("disconnect", function () {
          // console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    }
  }

  // no use
  initReadMessageSocket(__bool, message) {
    if (isPlatformBrowser(this.platformId)) {
      const $this = this;
      if (__bool == true) {
        this.socket.connect();

        this.socket.on("connect", function () {
          //  console.log('connected')
          $this.sendReadMessage(message);
        });
        this.socket.on("disconnect", function () {
          //  console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    }
  }

  // for notifications
  initNotificationSocket(__bool, message) {
    if (isPlatformBrowser(this.platformId)) {
      const $this = this;
      if (__bool == true) {
        this.socket.connect();

        this.socket.on("connect", function () {
          // console.log('connected')
          $this.sendNotificationMessage(message);
        });
        this.socket.on("disconnect", function () {
          // console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    }
  }


  initDriverChatSocket(__bool, message) {
   
    if (isPlatformBrowser(this.platformId)) {
      const $this = this;
      if (__bool == true) {
        this.socket.connect();

        this.socket.on("connect", function () {
          // console.log('connected')
          $this.sendDriverMessage(message);
        });
        this.socket.on("disconnect", function () {
          // console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    }
  }

  public loginOrderRoom(message) {
    if (isPlatformBrowser(this.platformId)) {
      console.log('init_order_room', message);
      this.socket.emit("loginOrderRoom", message);
    }
  }

  // get lat lng for map
  public orderTrackingProcess = () => {
    console.log('order_tracking_process.........');
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("updateOrderTracking", (message) => {
          observer.next(message);
        });
      });
    }
  };
}
