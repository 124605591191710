<div class="maintenanceBody">
    <div class="container">
        <div class="maintenanceWrapper">
            <div class="maintenanceLogo">
                <img src="assets/img/logo.png" alt="">
            </div>
            <div class="maintenanceImage">
                <img class="img1" src="assets/img/bbq1.png" alt="">
                <img class="img2" src="assets/img/bbq2.png" alt="">
                <img class="img3" src="assets/img/bbq3.png" alt="">
                <img class="img4" src="assets/img/bbq4.png" alt="">
            </div>
            <div class="maintenanceText">
                <h1>We are under maintenance</h1>
                <p>We're making the system more awesome.We'll be back shortly.</p>
            </div>
        </div>
    </div>
</div>