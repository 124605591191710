import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
// import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { ToastrModule } from 'ng6-toastr-notifications';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import {
  SwiperConfigInterface, SWIPER_CONFIG
} from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebChatService } from './chat-service.service';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OrderReceiptComponent } from './components/order-receipt/order-receipt.component';
import { CreditCardDirective } from './credit-card.directive';
import { InputMaskDirective } from './input-mask.directive';
import { LoadingScreenInterceptor } from './interceptor/loading.interceptor';
import { LoadingScreenComponent } from './shared/loading-screen/loading-screen.component';
import { AuthGuard } from './_guards/auth.guard';
import { ErrorInterceptor } from './_helpers';
import { UniversalInterceptor } from './_helpers/handel-interceptor.interceptor';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};




const config: SocketIoConfig = { url:`${environment.socketUrl}`, options: {} };



 

@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    MaintenanceComponent,
    InputMaskDirective,
    CreditCardDirective,
    OrderReceiptComponent
    
  ], 
  imports: [
    CommonModule,
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SocialLoginModule,
    BrowserAnimationsModule,

    HttpClientModule,
    // SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
   
    
    
    SocketIoModule.forRoot(config),
   

  ],
   
  providers: [
    Title,
    AuthGuard,
    WebChatService,
    { provide: HTTP_INTERCEPTORS, useClass: UniversalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              `${environment.googleClientId}`
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              `${environment.facebookClientId}`), 
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
