import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OrderReceiptComponent } from './components/order-receipt/order-receipt.component';


const routes: Routes = [

  { 
    path:'',
    loadChildren:()=>import('./components/home.module').then((m)=>m.HomeModule)
  },
 
  { 
    path:'',
    loadChildren:()=>import('./components/layout/layout-module').then((m)=>m.LayoutModule)
  },
 
  { path: "maintenance", component: MaintenanceComponent },
  { path: "order-receipt/:orderId", component: OrderReceiptComponent },
  { path: "**", redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
