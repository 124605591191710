import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError, Subject, Observer } from 'rxjs';
import { environment } from '../../environments/environment';
import { TestBed } from '@angular/core/testing';
import { promise } from 'protractor';
import { SocialAuthService } from 'angularx-social-login';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  apiDomain = `${environment.domain}`;
  loginApi = 'login?request_by=web';
  forgetPasswordApi = 'forgetpassword?request_by=web';
  resetPasswordApi = 'submit-forgetpassword';
  editProfileApi = "edit-profile";
  changePasswordApi = "change-password?request_by=web";
  socialLoginApi = 'social-login?request_by=web';
  updateProfileImageApi = "update-profile-image";
  deleteAccountApi = 'delete_account?request_by=web';
  updatePhoneNumberApi = 'update-phone-number?request_by=web';
  unreadNotificationCountApi = 'unread-notification-count?request_by=web';
  getOrderRecepitApi = 'orderreceipt';

  

  
  userLoginStatus = new Subject<boolean>();
  addPhoneNumber = new Subject<boolean>();
 
  profileImg; // delete for test only

  // sending header to http request
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Accept-Language': localStorage.getItem('browserLanguage')
  //   }),
  // };

  constructor(public router: Router, private http: HttpClient, private authService: SocialAuthService,private state :ActivatedRoute) { }

  login(data) {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage')
      }),
    };
    return this.http
      .post<any>(this.apiDomain + this.loginApi, data, httpOptions)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  socialLogin(data) {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage')
      }),
    };
    return this.http
      .post<any>(`${this.apiDomain}${this.socialLoginApi}`, data, httpOptions)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  forgetPassword(data) {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage')
      }),
    };
    return this.http
      .get<any>(
        `${this.apiDomain}${this.forgetPasswordApi}${data}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  resetPassword(data) {
   
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage')
      }),
    };
    return this.http
      .get<any>(
        `${this.apiDomain}${this.resetPasswordApi}?request_by=web${data}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  editProfile(data) {
    var header = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };
    return this.http
      .post<any>(
        `${this.apiDomain}${this.editProfileApi}`,
        data,
        header
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }



  uploadProfileImage(image: File) {
    var header = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };

    var formData = new FormData();
    formData.append('image', image);
    return this.http
      .post<any>(
        `${this.apiDomain}${this.updateProfileImageApi}`,
        formData,
        header
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }



  changePassword(data) {
    var header = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };
    return this.http.post<any>(`${this.apiDomain}${this.changePasswordApi}`, data, header)
      .pipe(map((res) => {
        return res;
      })
      );
  }

  deleteAccount() {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };
    return this.http
      .get<any>(
        `${this.apiDomain}${this.deleteAccountApi}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updatePhoneNumber(data)
  {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };
    return this.http
      .post<any>(
        `${this.apiDomain}${this.updatePhoneNumberApi}`,data,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  
  getUnreadNotificationCount()
  {
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': localStorage.getItem('browserLanguage'),
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }),
    };
    return this.http
      .get<any>(
        `${this.apiDomain}${this.unreadNotificationCountApi}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  


  logoutUser() {
    localStorage.removeItem('currentSubSationalUser');
    // localStorage.removeItem('browserLanguage');
    localStorage.removeItem('token');
    localStorage.removeItem('flagImg');
    this.authService.signOut()
      .then((res) => { })
      .catch((err) => {  });
    this.userLoginStatus.next(false);

    
    this.router.navigate(['/login'],{ queryParams: { returnUrl: this.router.url } });
  }

  getOrderRecepit(data){
    return this.http
      .get<any>(
        `${this.apiDomain}${this.getOrderRecepitApi}`+'/'+data
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
