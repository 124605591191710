import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.css']
})
export class OrderReceiptComponent implements OnInit {
  html: any = '';
  orderId: any = ''; 
  orderData: any= '';
  constructor(
    private loginService :LoginService,
    private activatedRoute 	: 	ActivatedRoute,
    public alertService: AlertService,
    private router: Router,
  ) {



    this.activatedRoute.params.subscribe((res) => {
      this.orderId = res.orderId;
    });
   }

  ngOnInit(): void {
    this.loginService.getOrderRecepit( this.orderId).subscribe((res: any) => {
        if (res?.status == 'success') {
 
         this.orderData  = res.data;
          
        } else {
          this.router.navigate(['/']);
        }
   
      }
    );
  }

}
