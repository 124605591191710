import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit {
  loading: boolean = false;

  constructor(public alertService: AlertService) {
    this.alertService.loading.pipe(debounceTime(200)).subscribe((res) => {
      this.loading = res;
    })
  }

  ngOnInit(): void {
  }

}
