// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// SUBSATIONAL LOCAL API KEY
// export const environment = {
//   production: false,

//   domain: 'http://subsationaladmin.dev21.obtech.inet/api/v3/',
//   mapApiKey: 'AIzaSyA6s497mKnN7hP7fzCRIOOAJ_wvpG-2RLM',
//   googleClientId: '213634455605-0018hb2r9p1h86kdqokunpf49gc5ncat.apps.googleusercontent.com',
//   facebookClientId: '121308616482881',
//   // stripPublistKey  : 'pk_test_51HKqYQJRcnm94qNowtYwz48JmOclL5ryuBRS60Xp8Chu8RULrUb2eIgnd4ansiE4C0uP0aMK4053VACQa1CIDS4n00p1I0mUbq',
//   stripPublistKey : 'pk_test_KWUbBUfLipJXZ4VE2PqBYZ4K',
//   socketUrl : 'https://subsns.stage04.obdemo.com/'
//   // socketUrl : 'https://ns.subseats.com/'
  
// };
 
// SUBSATIONAL STAGE04 KEYS
// export const environment = {
//   production: false,
//   // domain: 'https://subsational.stage04.obdemo.com/api/v1/',
//   domain: 'https://subsational.stage04.obdemo.com/api/v3/',
//   mapApiKey: 'AIzaSyA6s497mKnN7hP7fzCRIOOAJ_wvpG-2RLM',
//   googleClientId: '206948498181-n4a3a32h73odsa8ga4h9o96e1jqfbc5l.apps.googleusercontent.com',
//   // facebookClientId: '207757487190380',
//   facebookClientId: '320963679652138',
//   stripPublistKey  : 'pk_test_KWUbBUfLipJXZ4VE2PqBYZ4K',
//   socketUrl : 'https://subsns.stage04.obdemo.com/'

  
// };

 
export const environment = {
  production: true,
  domain: 'https://admin.subseats.com/api/v5/', 
  mapApiKey: 'AIzaSyA6s497mKnN7hP7fzCRIOOAJ_wvpG-2RLM',
  // googleClientId: '213634455605-0018hb2r9p1h86kdqokunpf49gc5ncat.apps.googleusercontent.com',
  // facebookClientId: '121308616482881',
  // googleClientId: 'AIzaSyCBfZFmjbvcLCfxRfTwv0-jcePTJAnLV2w',
  googleClientId: '905918875917-mff0a50j0m33ap6u3u0ueo5nq0lnakqj.apps.googleusercontent.com',
  facebookClientId: '183379819999346',
  stripPublistKey  : 'pk_live_51HKqYQJRcnm94qNo0Av7kzQmiYZkN1az0FC8RuiLl3qqN5V69QYFPZ7mDo5FRXH7fMQo5ndBKiWYpza1vqaHlYSF00qM5dMPOj',
  socketUrl : 'https://ns.subseats.com/'
};

// SUBSATIONAL LIVE KEYS
// export const environment = {
//   production: true,
//   domain: 'https://admin.subseats.com/api/v5/', 
//   mapApiKey: 'AIzaSyA6s497mKnN7hP7fzCRIOOAJ_wvpG-2RLM',
//   // googleClientId: '213634455605-0018hb2r9p1h86kdqokunpf49gc5ncat.apps.googleusercontent.com',
//   // facebookClientId: '121308616482881',
//   // googleClientId: 'AIzaSyCBfZFmjbvcLCfxRfTwv0-jcePTJAnLV2w',
//   googleClientId: '905918875917-mff0a50j0m33ap6u3u0ueo5nq0lnakqj.apps.googleusercontent.com',
//   facebookClientId: '183379819999346',
//   stripPublistKey  : 'pk_live_51HKqYQJRcnm94qNo0Av7kzQmiYZkN1az0FC8RuiLl3qqN5V69QYFPZ7mDo5FRXH7fMQo5ndBKiWYpza1vqaHlYSF00qM5dMPOj',
//   socketUrl : 'https://ns.subseats.com/'
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
