<!DOCTYPE html>
<html>

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Subsational</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap" rel="stylesheet">
    <style>
        @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900|Roboto:300,400,500,700,900&display=swap');

        body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: none;
            -ms-text-size-adjust: none;
            background: #ededed;
            font-family: 'Futura','Open Sans',sans-serif;
           
        }

        p {
            font-size: 16px;
            color: #000;
            font-family: 'Futura','Open Sans',sans-serif;
            
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Futura','Open Sans',sans-serif;
            font-size: 18px;
            margin-top: 0;
        }



        @media (max-width:767.98px) {

            .mobDeviceBody{
                padding:5px!important;
            }

            .logoMobile{
                padding:40px 0 20px!important;
            }
            .titleAmount{
                padding:0 10px!important;
            }
            .titleAmount h2.amtTitleamt{
                margin:20px 0 25px!important;
                font-size: 22px!important;
                font-family: 'Futura-Hv','Open Sans',sans-serif!important;
            }
            .titleAmount h2.desTitletag{
                margin:20px 0 25px!important;
                font-size: 18px!important;
                font-family: 'Futura-Hv','Open Sans',sans-serif!important;
            }

            .orderNOTable{
                padding: 40px 10px 5px!important;
            }

            .orderNOTable td{
                padding:0 0 10px 0!important;
                font-size: 14px!important;
                /* font-family: 'Futura','Open Sans',sans-serif!important; */
            }

            .orderItemName{
                padding:0 10px 0!important;
            }

            .orderItemName td{
                width:auto!important;
                font-size: 14px!important;
                /* font-family: 'Futura','Open Sans',sans-serif!important; */
                padding:5px 0!important;
            }

            .orderTotalTd{
                padding: 0 10px 65px!important;
            }
            .labelTotalGrand{
                width: auto!important;
                font-family: 'Futura-Hv','Open Sans',sans-serif!important;
                text-align: right;
            }

            .labelTotalGrand_amt{
                width:85px!important;
                font-family: 'Futura-Hv','Open Sans',sans-serif!important;
            }
            .borderTop_2{
                border-top:2px solid #333!important;
                border-bottom: 2px solid #333!important;
            }
            .bdrTpThin{
                border-top: 1px solid #A8A8A8!important;
            }
        }

    </style>
</head>

<body marginwidth="0" class="mobDeviceBody" marginheight="0" style="background-color: #f6f6f6; padding: 40px 15px;min-height: 100vh;">

    <table align="center" width="100%" cellspacing="0" cellpadding="0" style="max-width: 530px; width: 100%; background-color: #ededed; padding-top: 0; padding-bottom: 0; margin: 0 auto; border: 1px solid #eaeaea; border-radius: 4px;overflow: hidden;">
        <tbody>
            <tr>
                <td align="center" valign="top" style="width: 100%;">
                    <table border="0" align="center" width="100%" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td style="border-radius: 4px 4px 0 0;" bgcolor="#ededed" align="center"
                                    valign="top">
                                    <table border="0" align="center" width="100%" cellspacing="0" cellpadding="0"
                                        style="background-color: #fff;">
                                        <tbody>


                                            <tr>
                                                <td align="center" valign="top" class="logoMobile" style="padding: 60px 0 15px;">
                                                    <table border="0" align="center" width="100%" cellspacing="0"
                                                        cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td align="center" valign="top"><a href="#"><img
                                                                            src="assets/img/sublogo.png" alt="" width="225"></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="main" style="background-color: #fff;" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" class="titleAmount" style="padding: 30px 40px;" valign="top">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                           
                                            <tr>
                                                <td>
                                                    <h2 class="desTitletag" style=" box-sizing: border-box; font-size: 24px; color: #000; line-height: 1.2em; font-family: 'Futura','Open Sans',sans-serif; text-align: center; margin: 30px 0 0;">
                                                        Thanks for using Subsational.</h2>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="main" style="background-color: #fff;" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" class="orderNOTable" style="padding: 30px 60px 0;" valign="top">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td style="box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0 10px; line-height: 22px; color: #000000; font-family:Futura;">
                                                    Order&nbsp;#{{orderData?.order_number}}<br />
                                                    {{orderData?.ORDER_TIME}}</td>
                                            </tr>
                                            <tr>
                                                <td style="box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0 10px; line-height: 20px; color: #000000; font-family:Futura;">
                                                    <h6 style="margin: 0 0 2px;box-sizing: border-box; font-size: 14px; color: #000000; font-family:'Futura-Hv','Open Sans',sans-serif;" >
                                                        {{orderData?.restaurant_details?.name}}
                                                    </h6>
                                                    {{orderData?.restaurant_details?.address1}} <br/>
                                                    {{orderData?.restaurant_details?.city}} {{(orderData?.restaurant_details?.statecode) ? orderData?.restaurant_details?.statecode + ',' : ''}} {{orderData?.restaurant_details?.zipcode}}
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="main" style="background-color: #fff;" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" class="orderItemName" style="padding: 0 60px 0;" valign="top">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <ng-container *ngFor="let itmData of orderData.order_items">
                                            <tr >
                                                <td style="width:33%;box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000; font-family: 'Futura','Open Sans',sans-serif; border-top: 1px solid #eee;">
                                                    {{itmData?.name}}</td>
                                                <td style="width:33%;box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000; font-family: 'Futura','Open Sans',sans-serif; border-top: 1px solid #eee; text-align:right;">
                                                   x{{itmData?.qty}}</td>
                                                <td style="width:33%;box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000; font-family: 'Futura','Open Sans',sans-serif; border-top: 1px solid #eee; text-align:right;">
                                                    ${{itmData?.price}}</td>
                                            </tr></ng-container>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="main" style="background-color: #fff;padding: 0 60px;" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" class="orderTotalTd" style="padding: 0 60px 30px;" valign="top">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <tr>
                                               
                                                <td colspan="2" class="labelTotalGrand bdrTpThin" style="width:auto; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 1px solid #A8A8A8; text-align:right;">
                                                    Subtotal</td>
                                                <td class="labelTotalGrand_amt bdrTpThin" style="width:135px; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 1px solid #A8A8A8; text-align:right;">
                                                    ${{orderData?.SUB_TOTAL}}</td>
                                            </tr>
                                            <tr>
                                               
                                                <td colspan="2" class="labelTotalGrand bdrTpThin" style="width:auto; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 1px solid #A8A8A8; text-align:right;">
                                                    Taxes</td>
                                                <td class="labelTotalGrand_amt bdrTpThin" style="width:135px; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 1px solid #A8A8A8; text-align:right;">
                                                    ${{orderData?.TAXES}}</td>
                                            </tr>
                                            <tr>
                                                
                                                <td colspan="2" class="labelTotalGrand borderTop_2" style="width:auto; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 2px solid #333; border-bottom: 2px solid #333; text-align:right;">
                                                    Total</td>
                                                <td class="labelTotalGrand_amt borderTop_2" style="width:135px; box-sizing: border-box; font-size: 14px; vertical-align: top; padding:5px 0; line-height: 22px; color: #000000;  font-family: 'Futura-Hv','Open Sans',sans-serif; border-top: 2px solid #333; border-bottom: 2px solid #333; text-align:right;">
                                                    ${{orderData?.TOTAL}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    
                    
                    <table class="main" border="0" align="center" width="100%" cellspacing="0" cellpadding="0"
                        style="background-color: #da251d; padding: 15px 0;">
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <table class="two-left-inner" border="0" align="center" width="100%"
                                        style="max-width:600px;" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td align="center" valign="top">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p
                                                        style="text-align: center; font-size: 19px; font-family: 'Futura-Hv','Open Sans',sans-serif; color: #fff; max-width: 400px; margin: 0 auto 20px;">
                                                        Download our app to order food delivery from your favorite
                                                        restaurants</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top">
                                                    <a href="https://play.google.com/store/apps/details?id=com.subseats" style="display: inline-block;" target="_blank">
                                                        <img src="assets/img/gplay-icon.png" alt="" style="max-width: 150px;">
                                                    </a>
                                                    &nbsp;
                                                    <a href="https://apps.apple.com/us/app/subsational/id1582637440" style="display: inline-block;" target="_blank">
                                                        <img src="assets/img/app-icon.png" alt="" style="max-width: 150px;">
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="main" border="0" align="left" width="100%" cellspacing="0" cellpadding="0"
                        style="background-color: #2d2d2d;">
                        <tbody>
                            <tr>
                                <td align="left" valign="top" style="padding:20px 15px;">
                                    <table border="0" align="center" width="100%" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p
                                                        style="text-align:left;margin:0;font-size: 14px; color: #fff; line-height: 28px; font-weight: normal;">
                                                        Copyright © 2022, Subsational
                                                    </p>
                                                </td>
                                                <td>
                                                    <table border="0" align="right" width="180" cellspacing="0"
                                                        cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td align="center" width="" valign="top">
                                                                    <a href="https://www.facebook.com/SubSational/"><img src="assets/img/fb-icon.png"
                                                                            alt="" width="20"></a>
                                                                </td>
                                                                <td align="center" width="" valign="top">
                                                                    <a href="https://www.instagram.com/subsational/"><img
                                                                            src="assets/img/insta-icon.png" alt="" width="21"></a>
                                                                </td>
                                                                <td align="center" width="" valign="top">
                                                                    <a href="https://twitter.com/"><img
                                                                            src="assets/img/twitter-icon.png" alt=""
                                                                            width="22"></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>